;(function () {
    'use strict'

    function init(element) {
      if(element.querySelectorAll('.item-content').length > 0){
        element.classList.add('cmp-newsroom-list--has-description');
      }
      
      const links = element.querySelectorAll('.cmp-newsroom-list__pagination a')
      var headerHeight = document.querySelector('.experiencefragment.header').offsetHeight
      links.forEach((a) => {
        a.addEventListener('click', (e) => {
          e.preventDefault()
          const targetelement = e.target.closest('.cmp-newsroom-list__pagination').closest('.cmp-newsroom-list__wrapper')
          const targetElementPaginationLinks = e.target.closest('.cmp-newsroom-list__pagination').querySelectorAll('a')
          const active = targetelement.querySelector(
            '.cmp-newsroom-list__page.active'
          )

          let nextPageNumber = parseInt(active.dataset.page, 10)
          if (e.target.classList.contains('arrow-prev')) {
            nextPageNumber--
          } else if (e.target.classList.contains('arrow-next')) {
            nextPageNumber++
          } else {
            nextPageNumber = parseInt(e.target.dataset.page, 10)
          }
          const target = targetelement.querySelector(`.cmp-newsroom-list__page[data-page="${nextPageNumber}"]`)
          if (target && active) {
            const hasPrev = targetelement.querySelector(`.cmp-newsroom-list__page[data-page="${nextPageNumber - 1}"]`)
            const hasNext = targetelement.querySelector(`.cmp-newsroom-list__page[data-page="${nextPageNumber + 1}"]`)
            const prevArr = targetelement.querySelector('.cmp-newsroom-list__pagination a.arrow-prev')

            const nextArr = targetelement.querySelector('.cmp-newsroom-list__pagination a.arrow-next')
            targetElementPaginationLinks.forEach(l => l.classList.remove('current'))
            targetelement.querySelector(`.cmp-newsroom-list__pagination a[data-page="${nextPageNumber}"]`).classList.add('current')
            hasPrev ? prevArr.classList.remove('disabled') : prevArr.classList.add('disabled')
            hasNext ? nextArr.classList.remove('disabled') : nextArr.classList.add('disabled')

            active.classList.remove('active')
            target.classList.add('active')
            setTimeout(
              () => window.scroll({ top: target.offsetTop - headerHeight, behavior: 'smooth' }),
              0
            )
          }
        })
      })
    }

    function onDocumentReady() {
      const elements = document.querySelectorAll('.cmp-newsroom-list')
      for (let i = 0; i < elements.length; i++) {
        init(elements[i])
      }
    }

    if (document.readyState !== 'loading') {
      onDocumentReady()
    } else {
      document.addEventListener('DOMContentLoaded', onDocumentReady)
    }
  })()
