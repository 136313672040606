;(function () {
  'use strict'

  function init(element) {
    const logos = element.querySelectorAll('.cmp-supporters-list__logo a.toggle-supporter')
    var headerHeight = document.querySelector('.experiencefragment.header').offsetHeight

    logos.forEach((a) => {
      a.addEventListener('click', (e) => {
        e.preventDefault()
        const active = element.querySelector(
          '.cmp-supporters-list__item-large.active'
        )
        if (active) {
          active.classList.remove('active')
        }
        const target = document.querySelector(a.getAttribute('href'))
        if (target) {
          target.classList.add('active')
          setTimeout(
            () => window.scroll({ top: target.offsetTop - headerHeight, behavior: 'smooth' }),
            0
          )
        }
      })
    })

    const itemSelector = '.cmp-supporters-list__item-large'
    const logosContent = element.querySelectorAll(itemSelector)
    for (var i = 0; i < logosContent.length; i++) {
      const next = logosContent[i].querySelector('.arrow-next')
      const prev = logosContent[i].querySelector('.arrow-prev')
      logosContent[i].dataset.index = i
      next.dataset.index = i
      prev.dataset.index = i

      next.addEventListener('click', (e) => {
        const index = parseInt(e.target.dataset.index, 10)
        const nextContent = element.querySelector(`${itemSelector}[data-index="${index + 1}"]`)
        if (nextContent) {
          element.querySelector(`${itemSelector}.active`).classList.remove('active')
          nextContent.classList.add('active')
          const nextIndex = parseInt(nextContent.dataset.index, 10) + 1
          const hasNext = element.querySelector(`${itemSelector}[data-index="${nextIndex + 1}"]`)
          nextContent.querySelector('.arrow-prev').classList.remove('disabled')
          if (hasNext) {
            nextContent.querySelector('.arrow-next').classList.remove('disabled')
          } else {
            nextContent.querySelector('.arrow-next').classList.add('disabled')
          }
        }
      })

      prev.addEventListener('click', (e) => {
        const index = parseInt(e.target.dataset.index, 10)
        const prevContent = element.querySelector(`${itemSelector}[data-index="${index - 1}"]`)
        if (prevContent) {
          element.querySelector(`${itemSelector}.active`).classList.remove('active')
          prevContent.classList.add('active')
          const prevIndex = parseInt(prevContent.dataset.index, 10) - 1
          const hasPrev = element.querySelector(`${itemSelector}[data-index="${prevIndex - 1}"]`)
          prevContent.querySelector('.arrow-next').classList.remove('disabled')
          if (hasPrev) {
            prevContent.querySelector('.arrow-prev').classList.remove('disabled')
          } else {
            prevContent.querySelector('.arrow-prev').classList.add('disabled')
          }
        }
      })
    }
  }

  function onDocumentReady() {
    const elements = document.querySelectorAll('.cmp-supporters-list')
    for (let i = 0; i < elements.length; i++) {
      init(elements[i])
    }
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
