import ce from '../_common/createElement'
;(function () {
  'use strict'

  function toggleSearch() {
    const searchBtn = document.querySelector('.search-button')
    const searchWrapper = document.getElementById('top-search-modal')
    searchBtn.addEventListener('click', (e) => {
      searchBtn.classList.toggle('is-open')
      searchWrapper.classList.toggle('open')
    })
  }

  let timeout

  function sendSearchTrackingEvent(term, resultsLength) {
    const data = {
      name: 'searchevent',
      type: 'primary-search',
      query: term,
      results: resultsLength,
      filters: '',
    }

    if (window.cc.tracking.updateDatalayer) {
      window.cc.tracking.updateDatalayer('search', data, data.name)
    }
  }

  function populateSearchResultsDropdown(value, json, container) {
    const resultsContainer = container.querySelector(
      '.cmp-search__results-wrapper'
    )
    resultsContainer.style.display = json.length ? 'block' : 'none'
    if (
      resultsContainer.parentNode.classList.contains('cmp-search__dropdown') &&
      json.length
    ) {
      {
        resultsContainer.parentNode.style.display = 'block'
      }
      resultsContainer.parentNode.style.display = json.length ? 'block' : 'none'
    }
    resultsContainer.innerHTML = ''
    json.forEach((result) => {
      const resultItem = ce('p')
      const link = ce('a', result.title, {
        href: result.url,
        class: 'cmp-search__link',
        tracking: {
          ccName: 'ctaevent',
          ccAction: 'click',
          ccLabel: 'search result :: ' + result.title + ' :: ' + result.url,
        },
      })
      const iconTemplate = document.querySelector('.cmp-search__arrow-icon')
      const icon = iconTemplate.content.cloneNode(true)
      link.appendChild(icon)
      resultItem.appendChild(link)
      resultsContainer.appendChild(resultItem)
      window.cc.tracking.init()
    })

    if (value.length >= 3) {
      sendSearchTrackingEvent(value, json.length)
    }
  }

  function registerKeyUpEvent() {
    const searchInputs = document.querySelectorAll('.cmp-search__input')
    searchInputs.forEach((input) => {
      const form = input.parentNode.parentNode
      form.addEventListener('submit', (e) => e.preventDefault())
      input.addEventListener('keyup', (e) => {
        if (e.key === 'Escape' || e.key === 'Enter') {
          return false
        }
        const value = e.target.value
        const container = e.target.parentNode.parentNode.querySelector(
          '.cmp-search__dropdown'
        )
        const clearBtn =
          e.target.parentNode.parentNode.querySelector('.cmp-search__clear')
        container.style.display = value.length ? 'block' : 'none'
        if (value.length >= 3) {
          clearTimeout(timeout)
          const searchUrl =
            e.target.parentNode.parentNode.action +
            '?' +
            e.target.name +
            '=' +
            encodeURIComponent(value)
          timeout = setTimeout(
            function () {
              fetch(searchUrl)
                .then((response) => response.json())
                .then((json) =>
                  populateSearchResultsDropdown(value, json, container)
                )
            }.bind(this),
            250
          )
        } else {
          populateSearchResultsDropdown('', [], container)
          clearBtn.style.display = value.length ? 'block' : 'none'
        }
      })
    })

    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        const searchDropdowns = document.querySelectorAll(
          '.cmp-search__dropdown'
        )
        searchDropdowns.forEach((sd) => (sd.style.display = 'none'))
      }
    })
  }

  function registerClickEvent() {
    const clearInputBtns = document.querySelectorAll('.cmp-search__clear')

    clearInputBtns.forEach((clearBtn) => {
      clearBtn.addEventListener('click', (e) => {
        const resultsContainer = e.target.parentNode.parentNode.querySelector(
          '.cmp-search__results-wrapper'
        )
        clearBtn.style.display = 'none'
        resultsContainer.style.display = 'none'
      })
    })
  }

  function adjustPlaceholder() {
    const mediaQuery = 'screen and (max-width: 992px)'
    const searchInputs = document.querySelectorAll('.cmp-search__input')
    searchInputs.forEach((input) => {
      const placeholder = window.matchMedia(mediaQuery).matches
        ? input.dataset.mobilePlaceholder
        : input.dataset.defaultPlaceholder
      input.placeholder =
        placeholder && typeof placeholder != 'undefined' ? placeholder : ''
    })
  }

  function onDocumentReady() {
    registerKeyUpEvent()
    registerClickEvent()
    toggleSearch()
    adjustPlaceholder()
    window.addEventListener('resize', adjustPlaceholder)
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
