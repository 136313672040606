/* eslint-disable */

;(function () {
  'use strict'

  function headerSticky() {
    var header = document.querySelector('.experiencefragment.header')
    var sticky = header.offsetHeight
    if (window.pageYOffset >= sticky / 2) {
      header.classList.add('header--sticky')
    } else {
      header.classList.remove('header--sticky')
    }
  }

  function addSupOnRegisteredCopyrightTrademark(element) {
    element.forEach(function (el) {
      var html = el.innerHTML
      html = html.replace(/®/g, '<sup>&reg;</sup>')
      html = html.replace(/©/g, '<sup>&copy;</sup>')
      html = html.replace(/™/g, '<sup>&trade;</sup>')
      
      el.innerHTML = html
    })
  }

  function styleRegisteredTradeMark() {
    var elementTitleTradeMark = document.querySelectorAll('.cmp-title__text')
    var elementNavigationTitleTradeMark = document.querySelectorAll('.navigation a')
    var elementAnchorTradeMark = document.querySelectorAll('.cmp-in-page-anchors__item span')

    addSupOnRegisteredCopyrightTrademark(elementTitleTradeMark)
    addSupOnRegisteredCopyrightTrademark(elementNavigationTitleTradeMark)
    addSupOnRegisteredCopyrightTrademark(elementAnchorTradeMark)
  }

  window.onscroll = function () {
    headerSticky()
  }
  function onDocumentReady() {
    styleRegisteredTradeMark()
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
