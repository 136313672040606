import $ from 'jquery'

;(function () {
  'use strict'

  const selector = '.social-feed'
  function initSlick(element) {
    const container = element.querySelector('.cmp-social-feed')

    const options = {
      arrows: false,
      dots: true,
      slidesToShow: 2,
      rows: 2,
      infinite: false,
      adaptiveHeight: true,

      responsive: [
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    }

    $(container).slick(options)
  }

  function onDocumentReady() {
    const elements = document.querySelectorAll(selector)
    for (let i = 0; i < elements.length; i++) {
      initSlick(elements[i])
    }
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
