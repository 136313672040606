/* eslint-disable */
import ce from '../_common/createElement';

;(function () {
  'use strict'

  const MOBILE_BREAKPOINT = 480
  const TABLET_BREAKPOINT = 1024

  function parentMenuGroup(element) {
    while (!element.parentNode.classList.contains('menu-group')) {
      if (element.tagName.toLowerCase() === 'body') {
        return null
      }
      element = element.parentNode
    }
    return element.parentNode
  }

  function createBackLinkForItem(target, parentItem) {
    const targetLevel = parseInt(target.dataset.level, 10)
    const hasBack = target.querySelector('a[href="#back"]')

    if (
      hasBack === null &&
      ((window.innerWidth <= MOBILE_BREAKPOINT && targetLevel >= 0) ||
        (window.innerWidth > MOBILE_BREAKPOINT &&
          window.innerWidth <= TABLET_BREAKPOINT &&
          targetLevel === 0))
    ) {
      const content =
        '<span class="arrow"></span>' +
        (targetLevel === 0
          ? 'Menu'
          : parentItem.parentNode.parentNode.parentNode
              .querySelector('h3')
              .innerText.trim())
      const a = ce('a', content, {
        href: '#back',
        class: 'back-to-menu',
      })
      a.addEventListener('click', (e) => {
        e.preventDefault()
        const scrollContainer = document.querySelector('.scroll-container')
        if (window.innerWidth <= MOBILE_BREAKPOINT) {
          scrollContainer.style.transform = targetLevel
            ? `translateX(calc(-${100 * targetLevel}vw))`
            : null
        } else {
          scrollContainer.style.transform = targetLevel
            ? `translateX(calc(-${50 * (targetLevel - 1)}vw))`
            : null
        }
        a.parentNode.classList.remove('active')
        const submenus = document.querySelectorAll('.menu-group.active')
        for (let i = 0; i < submenus.length; i++) {
          if (submenus[i].dataset.level >= a.parentNode.dataset.level) {
            submenus[i].classList.remove('active')
          }
        }
      })
      target.insertBefore(a, target.firstChild)
    }
  }

  function registerMobileMenuToggle(element) {
    const target = document.querySelector(element.dataset.toggles)
    if (target) {
      element.addEventListener('click', (e) => {
        const scrollContainer = document.querySelector('.scroll-container')
        const parentGroup = parentMenuGroup(element)
        const itemLevel = parentGroup
          ? parseInt(parentGroup.dataset.level, 10)
          : -1
        const openedMenuGroups = document.querySelectorAll(`.menu-group.active`)
        for (let i = 0; i < openedMenuGroups.length; i++) {
          const groupLevel = parseInt(openedMenuGroups[i].dataset.level, 10)
          if (groupLevel > itemLevel) {
            openedMenuGroups[i].classList.remove('active')
          }
        }
        target.classList.add('active')

        // create the back link, if needed
        createBackLinkForItem(target, element)

        // scroll the container if needed
        const targetLevel = parseInt(target.dataset.level, 10)
        if (window.innerWidth <= MOBILE_BREAKPOINT && targetLevel >= 0) {
          scrollContainer.style.transform = `translateX(calc(-${
            100 * (targetLevel + 1)
          }vw))`
        } else if (window.innerWidth <= TABLET_BREAKPOINT && targetLevel == 0) {
          scrollContainer.style.transform = `translateX(calc(-${
            50 * (targetLevel + 1)
          }vw))`
        }
      })
    }
  }

  function registerMobileMenuEvents() {
    // open mobile menu
    const openLink = document.querySelector('a[href="#toggle-mobile-menu"]')
    if (openLink) {
      openLink.addEventListener('click', (e) => {
        e.preventDefault()
        const menu = document.querySelector('.mega-menu__mobile')
        if (menu) {
          menu.classList.toggle('open')
          openLink.classList.toggle('active')
          document.body.classList.toggle('mega-menu-isOpen')
        }
      })
    }

    // toggle mega menu items
    const mobileMenuToggles = document.querySelectorAll(
      '.mega-menu__mobile [data-toggles]'
    )
    for (let i = 0; i < mobileMenuToggles.length; i++) {
      registerMobileMenuToggle(mobileMenuToggles[i])
    }
  }

  function determineMaxMobileDepth() {
    const columns = document.querySelectorAll('.menu-group')
    let max = 0
    for (let i = 0; i < columns.length; i++) {
      const level = parseInt(columns[i].dataset.level, 10)
      max = Math.max(max, level)
    }
    return max + 2
  }

  function resizeMegaMenuScrollContainer(maxItems) {
    const width = window.innerWidth
    const container = document.querySelector(
      '.mega-menu__mobile-content .scroll-container'
    )
    if (!container) {
      return
    }
    if (width <= MOBILE_BREAKPOINT) {
      container.style.width = `calc(100vw * ${maxItems})`
    } else if (width <= TABLET_BREAKPOINT) {
      container.style.width = `calc(50vw * ${maxItems})`
    }
  }

  function onDocumentReady() {
    registerMobileMenuEvents()

    const maxMobileDepth = determineMaxMobileDepth()
    resizeMegaMenuScrollContainer(maxMobileDepth)
    window.addEventListener('resize', () => {
      resizeMegaMenuScrollContainer(maxMobileDepth)
    })
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
