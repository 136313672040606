;(function () {
    'use strict'
  
    function containerBG() {
      const container = document.querySelectorAll('.container.aem-GridColumn')
      for (let i = 0; i < container.length; i++) {
        const bgWrapper = container[i].querySelector('.container--bg-full-style')
        if (bgWrapper != null) {
          container[i].classList.add('has-bg')
        }
      }
    }
  
    function onDocumentReady() {
      containerBG()
    }
    if (document.readyState !== 'loading') {
      onDocumentReady()
    } else {
      document.addEventListener('DOMContentLoaded', onDocumentReady)
    }
  })()
  