;(function () {
  'use strict'

  function buttonAnchor() {
    const anchorBtn = document.querySelectorAll('.cmp-cta-button[href^="#"]')
    anchorBtn.forEach((anchor) => {
      if (anchor.getAttribute('href') == '#') return
      anchor.addEventListener('click', function (e) {
        e.preventDefault()
        const target = document.querySelector(this.getAttribute('href'))
        const headerOffset = document.querySelector(
          '.experiencefragment.header'
        ).offsetHeight
        const yOffset = target.getBoundingClientRect().top
        const y = yOffset + window.pageYOffset - headerOffset

        if (target) {
          window.scrollTo({ top: y, behavior: 'smooth' })
        }
      })
    })
  }

  function iconOnly() {
    const button = document.querySelectorAll('.cta-button')
    for (let i = 0; i < button.length; i++) {
      const text = button[i].querySelector('.cmp-cta-button__text')
      if (text == null) {
        button[i].classList.add('icon-only')
      }
    }
  }

  function onDocumentReady() {
    buttonAnchor()
    iconOnly()
  }
  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
