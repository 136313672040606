function populateClasses(element, classes) {
  classes = Array.isArray(classes) ? classes : [classes]

  for (let i = 0; i < classes.length; i++) {
    element.classList.add(classes[i])
  }
}

function populateAttribute(element, attribute, value) {
  element.setAttribute(attribute, value)
}

function populateDataAttributes(element, dataset) {
  for (const i in dataset) {
    if (dataset.hasOwnProperty(i)) {
      element.dataset[i] = dataset[i]
    }
  }
}

function populateTrackingAttributes(element, dataset) {
  for (const prop in dataset) {
    element.dataset[prop] = dataset[prop]
  }
}

export default function createElement(tag, content = null, options = {}) {
  const element = document.createElement(tag)

  if (content) {
    element.innerHTML = content
  }

  for (const i in options) {
    if (options.hasOwnProperty(i)) {
      switch (i) {
        case 'class':
          populateClasses(element, options[i])
          break
        case 'data':
          populateDataAttributes(element, options[i])
          break
        case 'tracking':
          populateTrackingAttributes(element, options[i])
          break
        default:
          populateAttribute(element, i, options[i])
          break
      }
    }
  }

  return element
}
