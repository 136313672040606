;(function () {
  'use strict'

  const selector = '.cmp-search-filter'

  function filterMembers(element, searchValue, filterValue) {
    const members = element.querySelectorAll(`${selector}__member`)
    const noResultsFound = element.querySelector(`${selector}__no-results`)

    if (!searchValue && !filterValue) {
      members.forEach((member) => (member.style.display = ''))
      noResultsFound.style.display = 'none'
      return
    }

    let visibleMembers = 0
    members.forEach((member) => {
      const name = member.querySelector(`${selector}__member-name`).innerHTML
      const showByName = searchValue.toLowerCase().trim() 
        ? name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
        : true
      const showByFilter =
        !filterValue ||
        member.dataset.roles.split(' ').indexOf(filterValue) >= 0

      if (showByName && showByFilter) {
        member.style.display = ''
        visibleMembers++
      } else {
        member.style.display = 'none'
      }
    })

    noResultsFound.style.display = visibleMembers ? 'none' : ''
  }


  function initSearchFilter(element) {
    const searchDropdown = element.querySelector(`${selector}__roles`)
    searchDropdown.addEventListener('click', (e) => {
      searchDropdown.classList.toggle('expanded')
    })

    const searchInput = element.querySelector(`${selector}__search input`)
    const filterDropdown = element.querySelectorAll(
      `${selector}__roles .select li`
    )

    searchInput.addEventListener('keyup', () => {
      const selectedFilter = element.querySelector(`${selector}__roles .select li.current`)
      const selectedFilterValue = selectedFilter ? selectedFilter.dataset.value : ''
      filterMembers(element, searchInput.value, selectedFilterValue)
    })

    for (let i = 0; i < filterDropdown.length; i++) {
      filterDropdown[i].addEventListener('click', (e) => {
        const item = filterDropdown[i]
        const parent = item.closest('.custom-select')

        filterMembers(
          element,
          searchInput.value,
          item.getAttribute('data-value')
        )
        parent.querySelector('.custom-select__label span').textContent =
          item.textContent

        parent
          .querySelectorAll('.custom-select__list li.current')
          .forEach(function (item) {
            item.classList.remove('current')
          })
        e.target.classList.add('current')
      })
    }
  }

  function onDocumentReady() {
    const elements = document.querySelectorAll(selector)
    for (let i = 0; i < elements.length; i++) {
      initSearchFilter(elements[i])
    }
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
